/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

$background-pagina: #f8f8f8;
$background-menu: #ffffff;
$color-texto-geral: #7b7b7b;

:root {
  --ion-font-family: 'Oxygen', sans-serif;

  * {
    outline: none;
  }
}

body {
  background: #f8f8f8;
}

button {
  color: #333;
}

.popover-duas-opcoes {
  app-popover-seletor {
    height: 112px;
  }
}

// button {
//   background: transparent;
// }

::selection {
  background: #1a998e;
  color: #fff;
}

.titlecase {
  text-transform: initial;

  &:first-letter {
    text-transform: uppercase;
  }
}

ion-toast::part(button) {
  color: #fff;
  background: var(--ion-color-primary);
  border-radius: 7px;
  font-weight: bold;
  padding: 5px 11px;
  margin-right: 5px;
  text-transform: none;
  font-weight: 700;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 0;
}

.span__botao-financeiro-desabilitado {
  opacity: 1;
  display: block;
  margin: 0 auto;
  color: #d06a6a;
  background: #ffffff;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: bold;
  margin-top: 10px;
  position: relative;
  box-shadow: 0px 0px 10px #d7d7d7;

  &:before {
    content: "";
    display: block;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    width: 0;
    position: absolute;
    background: transparent;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
  }
}

// Estilo dos Modais
@media screen and (min-width: 768px) {
  .custom-modal-config::part(content) {
    --width: 400px;
    --max-height: 800px;
    --height: 95%;
    --border-radius: 10px;
  }

  .custom-modal-seletor::part(content) {
    --width: 80%;
    --min-width: 410px;
    --max-width: 100%;
    --height: calc(100% - 200px);
    --max-height: 900px;
    --border-radius: 10px;
  }

  .tall-modal::part(content) {
    --width: 420px;
    --max-width: 95%;
    --height: 90%;
    --max-height: 90%;
    --border-radius: 10px;
    --background: #ffffff;
  }

  .financeiro-modal::part(content) {
    --width: 500px;
    --max-width: 95%;
    --height: 90%;
    --max-height: 90%;
    --border-radius: 10px;
    --background: #ffffff;
  }

  .custom-modal-animais::part(content) {
    --width: 80%;
    --min-width: 410px;
    --max-width: 100%;
    --height: calc(100% - 200px);
    --max-height: 900px;
    --border-radius: 10px;
  }
}

@media screen and (min-width: 500px) {
  .custom-modal::part(content) {
    --width: 420px;
    --max-width: 95%;
    --height: 90%;
    --max-height: 700px;
    --border-radius: 10px;
    --background: #ffffff;
  }
}

.custom-modal-date::part(content) {
  --width: 410px;
  --max-height: 380px;
  --max-width: 95%;
  --border-radius: 10px;
  box-shadow: 0 0 5px #888;
}

@media screen and (min-width: 700px) {
  .custom-modal-lotes::part(content) {
    --width: 80%;
    --min-width: 410px;
    --max-width: 100%;
    --height: calc(100% - 200px);
    --max-height: 900px;
    --border-radius: 10px;
  }
}

.popover-calendario::part(content) {
  --width: 350px;
  border-radius: 6px;
  --max-height: none;
}

.popover-categorias::part(content) {
  --width: 290px;
  border-radius: 10px;
  --max-height: none;
}

.popover-recurso-premium::part(content) {
  --width: 290px;
  border-radius: 6px;
  --max-height: none;
}

.modal-manejos::part(content) {
  --width: 100%;
  --height: 100%;
}

@media screen and (min-width: 1000px) {
  .modal-manejos-disponiveis::part(content) {
    --max-width: 900px;
    --width: 90%;
    --height: 800px;
    --max-height: 90%;
    --background: #fff;
    border-radius: 6px;
  }
}

@media screen and (min-width: 768px) {
  .modal-large::part(content) {
    --max-width: 1200px;
    --width: 90%;
    --height: 660px;
    --max-height: 90%;
    --background: #fff;
    border-radius: 6px;
  }
}

.modal-full-screen::part(content) {
  --width: 100%;
  --height: 100%;
}

.popover-selecionar-adicionar-cria::part(content) {
  --width: 280px;
  --background: #fff;
  border-radius: 6px;
}

.popover-lista-exposicoes::part(content) {
  --width: 280px;
  --min-height: 60px;
  --background: #fff;
  border-radius: 6px;
}

.popover-opcoes-filtros::part(content) {
  box-shadow: 0;
  --width: auto;
  --box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

ion-modal.modal-default:not(.overlay-hidden)~ion-modal.modal-default {
  --backdrop-opacity: 0.3;
}

.modal-ampliar-img {
  --background: #0000005c;

  &::part(content) {
    --width: 100%;
    --height: 100%;
  }

  ion-content {
    --background: transparent;
  }

  ion-header,
  ion-toolbar {
    background: #f8f8f8;
    --background: #f8f8f8;
  }
}

@media screen and (max-width: 500px) {
  .custom-modal-animais::part(content) {
    --width: 100%;
    --height: 100%;
    --max-height: 100%;
  }
}

@media screen and (min-width: 600px) {
  .custom-modal-menu-pesquisa {
    --width: 800px;
    --max-width: 90%;
    --height: 800px;
    --max-height: 90%;
    --border-radius: 10px;
    box-shadow: 0 0 5px #888;
  }

  .modal-fullScreen::part(content) {
    --width: 90%;
    --height: 90%;
    --border-radius: 6px;
  }

  .modal-conteudo::part(content) {
    --max-width: 100%;
    --width: 750px;
    --height: 100%;
    --border-radius: 6px;
  }

  .modal-compra-venda::part(content) {
    --width: 640px;
    --max-width: 90%;
    --height: 90%;
    --max-height: 90%;
    border-radius: 6px;
    transition: 300ms all ease-in-out;
  }

  .modal-venda::part(content) {
    --width: 90%;
    --max-width: 90%;
    --height: 90%;
    --max-height: 90%;
    border-radius: 6px;
  }
}

.custom-modal-desenhar-area {
  --width: 100%;
  --height: 100%;
}

ion-popover {
  --max-height: 380px;
}

.popoverOpcoesBotoes::part(content) {
  --width: 270px;
}

.popover-opcao-item-manejo-area::part(content) {
  --width: 180px;
}

.searchbar {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  background: #f6f6f6;
  border-radius: 4px;
  min-width: 212px;
  max-width: 100%;
  height: 38px;
  padding: 13px;
  margin-top: 25px;
  border: 1px solid rgba(0, 0, 0, 0.025);

  ion-icon {
    color: #9c9c9c;
  }

  input {
    width: 138px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;

    &::placeholder {
      font-size: 13px;
      color: #9e9e9e;
    }
  }
}

.header-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px 0 15px;

  ion-label {
    font-size: 1.2rem;
    font-weight: 300;
  }

  ion-icon {
    font-size: 2rem;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      color: var(--ion-color-primary);
      opacity: 0.4;
    }
  }
}

.header-search-modal {
  display: flex;
  flex-direction: column;
  padding: 20px 15px 0 15px;
  align-items: flex-start;

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 5px 0;

    ion-label {
      font-size: 1.2rem;
      font-weight: 500;
    }

    ion-icon {
      font-size: 2rem;
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        color: var(--ion-color-primary);
        opacity: 0.4;
      }
    }
  }
}

.custom-action-sheet .action-sheet-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .action-sheet-title,
  .button-cancel {
    flex: 100%;
    max-width: 100%;
  }

  .button-cancel {
    font-size: 16px;
    font-weight: bold;
  }

  .action-sheet-button:not(.button-cancel) {
    flex: 33.33%;
    max-width: 33.33%;
    text-transform: capitalize;

    border-right: 1px solid #e6e6e6;
  }
}

/* Geral */
h2.divisor {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  padding: 10px 3px 6px;
  color: #797979;
  display: flex;
  align-items: center;

  ion-icon {
    font-size: 15px;
    margin-right: 6px;
  }

  .small {
    font-size: 12px;
  }

  &.grande {
    font-size: 22px;
    font-weight: 400;
    padding: 20px 3px 20px;
    color: #212020;
  }

  &.margens {
    padding: 24px 6px 6px;
  }

  button {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 10px;
    color: #249a8e;
    background: #f1f1f1;
    padding: 5px 8px;
    margin-left: 7px;
    border-radius: 16px;
    display: flex;
    align-items: center;

    ion-icon {
      width: 10px;
      height: 10px;
      font-size: 10px;
    }

    &.main {
      background: #598ad4;
      color: #f1f1f1;
    }
  }
}

.custom-scroll {

  ::-webkit-scrollbar,
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 13px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid #f8f8f8;
    background-color: #b2b2b2;
  }
}

* {

  ::-webkit-scrollbar,
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 13px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 13px;
  }

  &.jexcel_content {
    &::-webkit-scrollbar:vertical {
      width: 16px;
    }

    &::-webkit-scrollbar:horizontal {
      height: 16px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid #f8f8f8;
    background-color: #b2b2b2;
  }
}

.placeholder-vazio {
  width: auto;
  text-align: center;
  padding: 20px;
  font-size: 1em;
  font-weight: 300;
  color: #88a5a1;
  margin: 10px 0;
  border-radius: 6px;
  height: calc(100% - 30px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  color: #777;
  font-weight: bold;
  padding: 100px 0;
  flex-direction: column;

  button {
    display: flex;
    background: #d07614;
    color: #fff;
    line-height: 30px;
    padding: 0 10px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 6px auto 0;
    white-space: nowrap;
    align-items: center;

    ion-icon {
      margin-right: 4px;
    }
  }
}

.vazio {
  width: auto;
  text-align: center;
  padding: 20px;
  font-size: 1em;
  font-weight: 300;
  color: #88a5a1;
  background: #f1f5f4;
  margin: 10px 0;
  border-radius: 6px;

  &.claro {
    background: #f3f6f6;
    color: #909c9a;
  }

  button {
    display: flex;
    background: #d07614;
    color: #fff;
    line-height: 30px;
    padding: 0 10px;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin: 6px auto 0;
    white-space: nowrap;
    align-items: center;

    ion-icon {
      margin-right: 4px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .vazio {
    margin: 10px;
  }
}

ion-header,
ion-toolbar {
  background: #f8f8f8;
  --background: #f8f8f8;
}

ion-header ion-title:not(ion-modal ion-header ion-title) {
  padding-left: 27px;
}

ion-header ion-button:not(ion-modal ion-header ion-button) {
  color: var(--ion-color-primary);
}

ion-header ion-menu-button,
ion-header ion-back-button {
  color: var(--ion-color-primary);
  margin-right: -25px;
  margin-left: 0;
}

@media screen and (min-width: 767px) {
  ion-header propriedade-nome {
    display: none;
  }
}

// ion-header ion-buttons.buttons-first-slot {
//   margin-right: -10px;
//}

ion-title {
  ion-spinner {
    width: 16px;
    height: 16px;
  }
}

/* Content */

ion-content {
  --background: #f8f8f8;

  --padding-start: 30px;
  --padding-end: 30px;

  @media screen and (max-width: 1023px) {
    --padding-start: 20px;
    --padding-end: 20px;
  }

  @media screen and (max-width: 767px) {
    --padding-start: 16px;
    --padding-end: 16px;
  }
}

ion-popover ion-content {
  --padding-start: 0;
  --padding-end: 0;
}

ion-modal ion-content {
  --padding-start: 16px;
  --padding-end: 16px;
}

ion-modal h2 {
  padding: 22px 0 16px;
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  color: #797979;
}

.hidden {
  display: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

p {
  margin: 0;
}

/* Form Modal */
.modal-header {
  background: #f8f8f8;

  // padding: 25px 20px 0;
  small {
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #6d908c;
  }

  .modal-header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding-bottom: 15px;

    p {
      font-size: 20px;
      color: #444444;
      font-weight: 500;
    }

    span {
      font-size: 20px;
      color: #444444;
    }

    div {
      z-index: 2;
      cursor: pointer;

      &:hover ion-icon {
        color: lighten(#245f58, 20%);
      }

      ion-icon {
        font-size: 1.8rem;
        color: #245f58;
        pointer-events: none;
        transition: 0.4s;
      }
    }
  }

  ion-searchbar {
    --background: #eeeeee;
    --box-shadow: none;
    --border-radius: 6px;
    margin-bottom: 15px;
  }
}

.modal-form {
  background: #f8f8f8;
  padding-bottom: 25px;

  p {
    color: #aaa;
    margin-bottom: 15px;
  }

  h2 {
    font-size: 18px;
    font-weight: 300;
    padding-top: 10px;
    margin: 0;
    color: #797979;
  }

  ion-checkbox {
    margin-right: 5px;
  }

  ion-select {
    --padding-start: 15px;
  }

  ion-item {
    --background: transparent;
    --border-radius: 6px;
    --background-hover: none;
    --ripple-color: transparent;
    --highlight-color-focused: transparent;
    --highlight-color-valid: rgba(0, 0, 0, 0.4);
    --highlight-color-invalid: rgba(0, 0, 0, 0.4);

    margin-top: 10px;

    &.datetime {
      --padding-bottom: 5px;
    }

    &.item-select ion-label,
    .label {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 500;
      color: #797979;
    }

    &.item-select {
      ion-text {
        color: #444;
        font-size: 12px;
      }

      ion-icon {
        font-size: 14px;
      }
    }

    ion-textarea,
    ion-input {
      color: #000;
      font-size: 15px;
      font-weight: 500;
      --placeholder-color: #aaa;
      --placeholder-font-weight: 500;
      --placeholder-opacity: 1;
    }

    ion-input[placeholder='dd/mm/yyyy'] input,
    ion-input[placeholder='Selecionar'] input {
      cursor: pointer !important;
    }

    ion-input:not([placeholder='dd/mm/yyyy']) input::placeholder {
      text-transform: capitalize;
      font-size: 15px;
    }

    input[type='date'] {
      background: transparent;
      width: 100%;
      outline: none;
      border: none;
      padding-bottom: 5px;
    }

    ion-textarea {
      font-size: 15px;
    }

    ion-select {
      padding: 10px 0;
      color: #000;
      font-weight: 500;
      font-size: 15px;
      cursor: pointer;
    }
  }

  .input-select {
    position: relative;
    width: 100%;
    padding: 20px 15px 10px 5px;
    border-radius: 6px;
    background: #efefef;
    margin-bottom: 10px;

    select {
      width: 100%;
      height: 25px;
      font-weight: bold;
      cursor: pointer;
      border-radius: 0;
      background: transparent;
      border: none;
      color: #000;
      outline: none;
      font-size: 14px;
    }

    .label-stacked {
      position: absolute;
      top: 10px;
      left: 8px;
      text-transform: capitalize;
      font-size: 10px;
      font-weight: 500;
      color: #797979;
    }
  }

  .input-date {
    // display: flex;
    align-items: center;

    .label {
      font-size: 11px;
    }

    ion-icon {
      font-size: 1.2rem;
      cursor: pointer;
    }
  }

  .bg-transparent {
    --background: transparent;
  }

  ion-card {
    margin: 20px 0 10px 0;

    ion-card-title {
      padding: 10px 0 5px 20px;
      font-size: 18px;
      font-weight: 300;
      color: #797979;
    }

    ion-card-content {
      padding-top: 0;
    }
  }

  .segment {
    background: #efefef;
    margin-bottom: 15px;
    border-radius: 0;
    border: none;

    ion-segment-button {
      --color-checked: #006257;

      ion-icon {
        font-size: 1rem;
      }

      &::part(indicator-background) {
        background-color: #d0e2de;
        border-radius: 6px;
      }
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

@media screen and (min-width: 1024px) {
  ion-modal {

    .jcalendar-container,
    .jexcel_contextmenu,
    .jexcel .jdropdown-container {
      margin-left: 0;
    }
  }
}

.jexcel_richtext {
  display: none;
}

ion-footer,
ion-modal ion-footer {
  background: #f8f8f8;
  --background: #f8f8f8;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: row-reverse;
  padding: 0 15px;
}

.badge-situacao {
  border-radius: 2px;
  display: flex;
  place-items: center;
  padding: 2px 5px;

  .badge-situacao-label {
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    text-transform: uppercase;
  }
}

.situacao {
  background: #e4e4e4;

  .badge-situacao-label {
    color: #747474;
  }
}

.reprodutiva {
  background: #a870a2;
}

.produtiva {
  background: #7b7bb9;
}

.sanitaria {
  background: #bf5d3f;
}

// Tabela - Importação Pesagens

.pesagens-col-animal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  small {
    background: #f1f1f1;
    border-radius: 3px;
    padding: 1px 3px;
    color: #6d6d6d;
  }

  span {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// cadastro-manejos

@keyframes float {
  50% {
    transform: translateX(8px);
  }
}

@keyframes floatVertical {
  50% {
    transform: translateY(8px);
  }
}

.container-popover {
  position: absolute;

  &.vertical {
    animation: floatVertical 1.5s ease-in-out infinite;
  }

  &:not(.vertical) {
    animation: float 1.5s ease-in-out infinite;
  }

  .custom-popover {
    position: relative;
    width: auto;
    background: #e4ebe9;
    border-radius: 6px;
    padding: 10px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #44a89c;
    font-weight: 600;
    font-size: 15px;
    white-space: nowrap;
    z-index: 10;
  }

  .popover-arrow-left {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-right: 10px solid #e4ebe9;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
    width: 0;
    height: 0;
  }

  .popover-arrow-down {
    position: absolute;
    left: 10px;
    bottom: -15px;
    transform: translateY(-50%);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 10px solid #e4ebe9;
    width: 0;
    height: 0;
  }

  .popover-arrow-up {
    position: absolute;
    left: 10px;
    top: 0;
    transform: translateY(-50%);
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #e4ebe9;
    width: 0;
    height: 0;
  }
}

app-manejo-area-altura-capim,
app-manejo-sanitario-novo,
app-manejo-area-nutricional {
  .componentes-manejo {
    section.manejo-single {
      min-height: 60px;
      height: auto;
    }

    .manejo-input:not(.pendente) {

      span.label,
      input {
        background: #fff;

        &::placeholder {
          color: #aaa;
        }
      }
    }
  }
}

app-manejo-reprodutivo-diagnostico-gestacao,
app-manejo-reprodutivo-inseminacao {
  .componentes-manejo {
    section.manejo-single {
      min-height: 60px;
      height: auto;
      gap: 25px;
      padding: 10px 14px;
      flex-wrap: wrap;

      .manejo-avancados {
        display: flex;
        flex-basis: 100%;
        margin-left: 145px; 
        align-items: center;
        gap: 15px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .componentes-manejo {
      section.manejo-single {
          padding: 50px 10px 14px;
          .manejo-avancados {
            margin-left: 0; 
          }
      }
    }
  }
}

app-manejo-geral-observacao {
  .componentes-manejo {
    vasta-input {
      width: 100%;
      max-width: calc(100vw - 40px);
      
      .manejo-input {
        width: 100%;
        max-width: calc(100vw - 40px);
      }

      @media screen and (min-width: 768px) {
        max-width: calc(100vw - 500px);

        .manejo-input {
          max-width: calc(100vw - 500px);
        }
      }
    }
  }
}

.componentes-manejo {
  padding: 3px 10px 3px 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;

  &:last-child {
    padding-bottom: 6px;
  }

  section.manejo-single {
    background: #fbfbfb;
    border-radius: 7px;
    padding: 4px 14px;
    min-width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    gap: 18px;
    position: relative;

    &.edited {
      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background: #ccc;
        border-radius: 6px;
        margin: 4px 0 0;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0;
      }
    }

    .manejo-nome {
      width: 120px;
      font-size: 14px;
      color: #565656;
    }

    .container-acoes {
      margin-left: auto;
      display: flex;
      align-items: center;

      button {
        background: transparent;
        cursor: pointer;

        &:disabled {
          opacity: 0.3;
        }

        ion-icon {
          font-size: 20px;
          color: #b3b3b3;
        }
      }
    }
  }

  .manejo-segment {
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    button {
      width: 100%;
      background: #fff;
      height: 100%;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
      padding: 0 10px;
      color: #a8a8a8;
      white-space: nowrap;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      &:hover {
        opacity: 0.7;
      }

      &.active {
        background: #e3eeed;
        color: var(--ion-color-primary);
        font-weight: 700;
      }
    }

    &.pendente {
      button {
        background: #fef6f6;
        color: #f2acac;
        border: 1px solid #ffe8e8;

        &:not(:last-child) {
          border-right: 0;
        }
      }
    }
  }

  .manejo-input {
    position: relative;
    width: fit-content;
    min-width: 80px;
    height: 35px;

    input {
      width: 100%;
      height: 100%;
      padding: 3px 8px 0;
      border-radius: 4px;
      border: 1px solid #d0d0d0;
      background: #fbfbfb;
      font-size: 14px;
      position: absolute;
      left: 0;

      &::placeholder {
        font-size: 14px;
      }
    }

    span.label {
      background: #fbfbfb;
      padding: 0 3px;
      font-size: 10px;
      color: #656565;
      font-weight: 700;
      text-transform: uppercase;
      height: 13px;
      margin: -10px 6px -7px 3px;
      position: relative;
      display: block;
      width: fit-content;
      z-index: 3;
      pointer-events: none;
    }

    &.pendente {
      input {
        border: 1px solid #ffe8e8;
        background: #fef6f6;

        &::placeholder {
          color: #f2acac;
        }
      }

      span.label {
        background: #fef6f6;
        color: #ef9494;
      }
    }
  }

  .inseminacao .manejo-button {
    width: 150px;
  }

  .manejo-button {
    position: relative;
    width: 110px;
    height: 35px;

    button {
      width: 100%;
      height: 100%;
      padding: 5px 8px 0;
      border-radius: 4px;
      border: 1px solid #d0d0d0;
      background: #fbfbfb;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      ion-icon {
        margin-right: 4px;
        font-size: 22px;
        pointer-events: none;

        &[name='caret-down-outline'] {
          min-width: 15px;
          width: 15px;
        }
      }

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
        text-align: left;
        pointer-events: none;
      }
    }

    span.label {
      background: #fbfbfb;
      padding: 0 3px;
      position: absolute;
      top: -6px;
      left: 5px;
      font-size: 10px;
      color: #656565;
      font-weight: 700;
      text-transform: uppercase;
      white-space: nowrap;
      pointer-events: none;
    }

    &.pendente {
      button {
        border: 1px solid #ffe8e8;
        background: #fef6f6;

        color: #f2acac;
      }

      span.label {
        background: #fef6f6;
        color: #ef9494;
      }
    }
  }

  .manejo-select {
    position: relative;
    width: 110px;
    height: 35px;

    select {
      width: 100%;
      height: 100%;
      padding: 5px 8px 0;
      border-radius: 4px;
      border: 1px solid #d0d0d0;
      background: #fbfbfb;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(.filled) {
        background: #fef6f6;
        color: #d9b6b6;
        border-color: #fee8e8;

        option {
          background: #fff;
          color: #000;
        }
      }
    }

    span.label {
      background: #fbfbfb;
      padding: 0 3px;
      position: absolute;
      top: -6px;
      left: 5px;
      font-size: 10px;
      color: #656565;
      font-weight: 700;
      text-transform: uppercase;
      white-space: nowrap;
      pointer-events: none;
    }

    &.pendente {
      button {
        border: 1px solid #ffe8e8;
        background: #fef6f6;

        color: #f2acac;
      }

      span.label {
        background: #fef6f6;
        color: #ef9494;
      }
    }
  }

  .container-manejo {
    position: relative;
  }

  .manejo-alerta {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    background-color: #434343;
    translate: -33%;
    width: 257px;
    padding: 10px 10px;
    margin-top: 12px;
    border-radius: 6px;
    z-index: 5;

    .titulo {
      width: 237px;
      height: 20px;
      font-weight: 700;
      margin-bottom: 3px;
      flex-grow: 0;
      color: #fff;
    }

    .mensagem {
      color: #fff;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      width: 100%;
    }

    cta-buttons {
      width: 100%;
      margin: 8px 0 0;

      .container-buttons {
        justify-content: space-between;

        button {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;

          ion-icon {
            color: #fff;
            font-weight: bold;
            font-size: 24px;
          }
        }

        button:last-child {
          background-color: #5f5f5f;
          color: #fff;
        }
      }
    }
  }

  .manejo-alerta:after {
    content: '';
    left: 50%;
    transform: translate(-10px, -10px);
    color: #434343;
    border-bottom: 10px solid;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    top: 0;
  }

  .manejo-info {
    min-width: 80px;
    text-align: left;

    .label {
      font-weight: 700;
      color: #a8a8a8;
      text-transform: uppercase;
      font-size: 9px;
      line-height: 11px;
      display: block;
    }

    .content {
      font-size: 15px;
      line-height: 16px;
      color: #565656;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      grid-gap: 5px;
      gap: 5px;
      margin-top: 2px;

      &.status-erro {
        max-width: 260px;
        color: #d32f2f;
      }

      small {
        color: #8a8a8a;
      }

      ion-icon {
        font-size: 16px;
      }

      .status {
        width: 10px;
        height: 10px;
        background: #ccc;
        border-radius: 6px;
        margin: 4px 0 0;

        &.verde {
          background: var(--ion-color-primary);
        }

        &.amarelo {
          background: #ffc107;
        }

        &.vermelho {
          background: #d32f2f;
        }
      }
    }
  }

  .manejo-opcoes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .label {
      font-weight: 700;
      font-size: 10px;
      color: #a8a8a8;
      text-transform: uppercase;
      line-height: 14px;
      width: fit-content;
    }

    .container-opcoes {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      button {
        padding: 2px 4px;
        background: #e9f1f8;
        border-radius: 3px;
        color: #668fb3;
        font-size: 10px;
        line-height: 14px;
        min-height: 20px;
        font-weight: 700;
        white-space: nowrap;
        text-transform: uppercase;
        text-overflow: ellipsis;
        max-width: 120px;
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 3px;
        text-align: left;

        &.active {
          background: #d2e2f0;
        }

        &.femea {
          background: #ffebf2;
          color: #de85de;

          &.active {
            color: #ffebf2;
            background: #de85de;
          }
        }
      }
    }
  }

  .manejo-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 6px 12px 6px 8px;
    color: #ea7352;
    border-radius: 20px;
    white-space: nowrap;
    border: 1px solid #f7d3c8;
    cursor: pointer;
    user-select: none;

    .checkbox {
      pointer-events: none;
      width: 20px;
      height: 20px;
      background: #fceeea;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      border: 1px solid #f7d3c8;
      transition: background-color ease-in-out 200ms;

      ion-icon {
        color: #fceeea;
        transition: color ease-in-out 200ms;
      }
    }

    &.checked {
      border-color: #fceeea;
      background: #fdeeea;

      .checkbox {
        background: #ea7352;

        ion-icon {
          color: #fff;
        }
      }
    }

    .label {
      pointer-events: none;
    }
  }

  .manejo-btn {
    width: fit-content;
    height: 44px;
    border-radius: 6px;
    background: #e5f0ef;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: 0 13px 0 10px;

    div,
    span {
      pointer-events: none;
    }

    .icon {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      ion-icon {
        font-size: 20px;
      }
    }

    &.add {
      .icon {
        width: 24px;
        height: 24px;
        background: #fff;

        ion-icon {
          color: var(--ion-color-primary);
        }
      }
    }

    &.sanitario {
      background: #FFF1ED;
      color: #EA7352;

      span {
        color: #EA7352;
      }

      .icon {
        width: 24px;
        height: 24px;
        background: #fff;

        ion-icon {
          color: #EA7352;
        }
      }
    }

    &.lote {
      background: #fff;
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.08);

      .icon {
        width: 20px;
        height: 20px;
        background: #f6f6f6;

        ion-icon {
          color: var(--ion-color-primary);
        }
      }
    }

    span {
      font-weight: bold;
      text-transform: uppercase;
      color: var(--ion-color-primary);
    }
  }

  .item-sanitario,
  .item-nutricional,
  .item-piquete {
    width: 100%;
    height: 54px;
    border-radius: 6px;
    background: #fff;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    position: relative;
    cursor: initial;
    padding-left: 12px;

    .labels {
      display: flex;
      gap: 5px;
    }

    .inputs {
      display: flex;
      gap: 10px;
      margin-right: 10px;
    }

    .infos {
      display: flex;
      gap: 10px;
    }

    @media screen and (max-width: 940px) {
      flex-wrap: wrap;
      height: auto;
      padding: 0px 10px 16px 10px;

      .inputs {
        width: 100%;
        margin: 0;

        vasta-input {
          margin: 0;
          width: 50%;

          .manejo-input {
            width: 100%;
            min-width: 100%;
          }
        }
      }

      .infos {
        margin-top: 10px;
        width: 100%;
        gap: 0;

        .manejo-info {
          width: 50%;
          padding: 0 5px;
        }
      }

      .labels {
        width: 100%;
        padding: 10px 2px 20px;

        .info {
          width: auto;
        }
      }
    }

    &.edited:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background: #ccc;
      border-radius: 6px;
      margin: 4px 0 0;
      position: absolute;
      top: -3px;
      left: -3px;
      margin-top: 0;
    }

    .icon {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f6f6f6;

      ion-icon {
        font-size: 20px;
        color: var(--ion-color-primary);
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
      width: 150px;
      overflow: hidden;

      small {
        text-transform: uppercase;
        color: #b1b1b1;
      }

      span {
        font-weight: bold;
        text-transform: uppercase;
        text-align: left;
        color: var(--ion-color-primary);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }

    ion-icon {
      font-size: 16px;
    }

    & > .item-opcoes,
    & > ion-icon {
      position: absolute;
      top: 17px;
      right: 2px;
      font-size: 20px;
      cursor: pointer;
    }

    & > .item-observacoes {
      position: absolute;
      top: 17px;
      right: 30px;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .item-sanitario {
    .icon {
      ion-icon {
        color: #EA7352;
      }
    }

    .info {
      span {
        color: #EA7352;
      }
    }

    button {
      background: transparent;
      margin-left: auto;

      &:last-of-type {
        margin-left: 5px;
      }

      ion-icon {
        font-size: 20px;
        color: #b3b3b3;
      }

      &:disabled {
        opacity: 0.3;
      }
    }

    .container-opcoes {
      button {
        background: #fff1ed;
        color: #ea7351;

        &.active {
          background: #f6b9a8;
          color: #fff;
        }
      }
    }
    
    @media screen and (max-width: 767px) {
      .manejo-opcoes {
        padding-top: 15px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    section.manejo-single {
      // flex-direction: column;
      height: auto;
      gap: 15px;
      align-items: flex-start;
      padding: 50px 10px 14px;
      flex-wrap: wrap;

      .manejo-nome {
        white-space: nowrap;
        position: absolute;
        top: 7px;
        left: 8px;
        text-transform: uppercase;
        font-size: 12px;
      }

      cta-buttons {
        margin: 0;
      }

      .container-acoes {
        position: absolute;
        top: 11px;
        right: 3px;
        flex-direction: column-reverse;
        gap: 10px;
      }
    }
  }
}

ion-header > cta-buttons {
  margin: 0px 17px 13px;
}

ion-footer > cta-buttons {
  padding-bottom: var(--ion-safe-area-bottom);
}

.btn-importacao-selecionar-animal {
  font-weight: 700;
  background-color: #3e8c83;
  color: #fff;
  padding: 3px 6px;
  margin: -3px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 12px;
}

.container-resumo-manejos {
  padding: 0 0 8px 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  min-height: 30px;

  .item-manejo {
    max-width: 250px;
    height: 22px;
    padding: 3px 8px 3px 6px;
    background: #e9f4f5;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    ion-icon,
    .qtd-animais {
      color: var(--ion-color-primary);
      pointer-events: none;
    }

    ion-icon {
      width: 23px;
    }

    .tipo-manejo {
      display: block;
      width: 100%;
      font-size: 10px;
      line-height: 16px;
      color: var(--ion-color-primary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: uppercase;
    }

    .qtd-animais {
      font-size: 11px;
      line-height: 11px;
      font-weight: 700;
      pointer-events: none;
    }

    &.verde {
      background: #dfebe9;

      ion-icon,
      .tipo-manejo,
      .qtd-animais {
        color: #0d8270;
      }
    }

    &.laranja {
      background: #fcf5ef;

      ion-icon,
      .tipo-manejo,
      .qtd-animais {
        color: #f79f64;
      }
    }

    &.vermelho {
      background: #f7eceb;

      ion-icon,
      .tipo-manejo,
      .qtd-animais {
        color: #cf7272;
      }
    }

    &.preto {
      background: #ededed;

      ion-icon,
      .tipo-manejo,
      .qtd-animais {
        color: #7e7e7e;
      }
    }

    &.azul {
      background: #e5f0f3;

      ion-icon,
      .tipo-manejo,
      .qtd-animais {
        color: #0b90ad;
      }
    }

    &.amarelo {
      background: #ecdeca;

      ion-icon,
      .tipo-manejo,
      .qtd-animais {
        color: #e3b573;
      }
    }
  }
}

.segment-wrapper {
  display: flex;
  width: 100%;
  gap: 16px;

  button.button-segment {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #fff;
    border-radius: 30px;
    height: 33px;
    gap: 6px;
    cursor: pointer;
    color: #969696;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    white-space: nowrap;

    &.active {
      font-weight: bold;
      color: var(--ion-color-primary);
    }

    .icone {
      color: var(--ion-color-primary);
      font-size: 18px;
    }

    button.close {
      background-color: transparent;
      padding: 10px;
      margin: 0 -6px;
      display: flex;
      align-items: center;
      justify-content: center;

      .remover {
        color: #d32f2f;
        font-size: 17px;
      }
    }
  }
}

body.propriedade-exemplo {

  .permissao-escrita-propriedade:not(.acao-cadastro),
  .permissao-escrita-areas:not(.acao-cadastro),
  .permissao-escrita-animais:not(.acao-cadastro),
  .permissao-escrita-lotes:not(.acao-cadastro),
  .permissao-escrita-manejos:not(.acao-cadastro),
  .permissao-escrita-financeiro:not(.acao-cadastro),
  .permissao-escrita-calendario:not(.acao-cadastro),
  .permissao-escrita-estoque:not(.acao-cadastro),
  .permissao-escrita-relatorios:not(.acao-cadastro),
  .permissao-escrita-usuarios_360debolso:not(.acao-cadastro),
  .permissao-escrita-aprovacoes:not(.acao-cadastro) {
    opacity: 0.6 !important;
    pointer-events: none !important;
  }
}

body.plano-basico:not(.propriedade-exemplo) {
  .item-bloqueado-plano-basico {
    opacity: 0.6 !important;
    filter: blur(1px);
  }
}

body.hidden-plano-basico {
  .item-premium {
    display: none!important;
  }

  .item-bloqueado-plano-basico:not(app-opcoes-botoes .item-bloqueado-plano-basico) {
    opacity: 0 !important;
    pointer-events: none !important;
    display: none;
  }

  app-opcoes-botoes .item-bloqueado-plano-basico {
    pointer-events: none !important;

    ion-icon {
      display: none;
    }
  }
}

.top-bar {
  display: flex;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -34px;
  width: 100%;
  height: 34px;
  background: #fff;
  z-index: 999999999;
  text-align: center;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 6px;
  line-height: 10px;
  padding: 0;
  align-items: center;
  justify-content: center;
  transition: 300ms ease-in-out all;
  padding-top: 0;
  margin-top: var(--ion-safe-area-top);
  transform: translateY(-34px);

  .top-bar-wrapper {
    background: #3e8c83;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    height: 48px;
    transform: translateY(-40px);
    padding: 4px 8px 4px;
    top: -48px;

    .top-bar-wrapper {
      border-radius: 8px;
      padding: 0px 10px 0;
      height: 40px;
    }
  }

  .top-bar-mensagem {
    font-size: 14px;
    color: #fff;
    margin-right: 12px;
    font-family: Oxygen;
    font-weight: bold;
    line-height: 1;
  }

  button {
    border-radius: 6px;
    width: fit-content;
    height: fit-content;
    text-transform: uppercase;
    font-size: 12px;
    padding: 3px 9px 2px;
    line-height: 18px;
    background: #f19714;
    color: #fff;
    font-weight: bold;
    white-space: nowrap;
  }

  @media screen and (max-width: 600px) {
    flex-direction: row-reverse;
    gap: 10px;

    .top-bar-mensagem {
      font-size: 13px;
      text-align: left;
    }
  }
}

html.top-bar-shown {
  body {
    transition: 300ms ease-in-out height;
    height: calc(100% - 34px);
    margin-top: 34px;
    transition: 300ms ease-in-out all;
  }

  .top-bar {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }

  ion-popover {
    margin-top: -34px;
  }

  @media screen and (max-width: 1280px) {
    body {
      height: calc(100% - 48px);
      margin-top: 48px;
    }

    ion-popover {
      margin-top: -48px;
    }
  }
}

body.sem-permissao-leitura-propriedade .permissao-leitura-propriedade,
body.sem-permissao-leitura-areas .permissao-leitura-areas,
body.sem-permissao-leitura-animais .permissao-leitura-animais,
body.sem-permissao-leitura-lotes .permissao-leitura-lotes,
body.sem-permissao-leitura-manejos .permissao-leitura-manejos,
body.sem-permissao-leitura-financeiro .permissao-leitura-financeiro,
body.sem-permissao-leitura-calendario .permissao-leitura-calendario,
body.sem-permissao-leitura-estoque .permissao-leitura-estoque,
body.sem-permissao-leitura-relatorios .permissao-leitura-relatorios,
body.sem-permissao-leitura-usuarios_360debolso .permissao-leitura-usuarios_360debolso,
body.sem-permissao-leitura-aprovacoes .permissao-leitura-aprovacoes,
body.sem-permissao-escrita-propriedade .permissao-escrita-propriedade,
body.sem-permissao-escrita-areas .permissao-escrita-areas,
body.sem-permissao-escrita-animais .permissao-escrita-animais,
body.sem-permissao-escrita-lotes .permissao-escrita-lotes,
body.sem-permissao-escrita-manejos .permissao-escrita-manejos,
body.sem-permissao-escrita-financeiro .permissao-escrita-financeiro,
body.sem-permissao-escrita-calendario .permissao-escrita-calendario,
body.sem-permissao-escrita-estoque .permissao-escrita-estoque,
body.sem-permissao-escrita-relatorios .permissao-escrita-relatorios,
body.sem-permissao-escrita-usuarios_360debolso .permissao-escrita-usuarios_360debolso,
body.sem-permissao-escrita-aprovacoes .permissao-escrita-aprovacoes {
  display: none !important;
}

app-modal-manejos {
  header-tabs.cadastrar-manejos {
    margin-top: -8px;

    .container-tabs {
      justify-content: space-between;
      gap: 0;

      .tab {
        width: 33.333%;
        text-align: center;

        p {
          font-size: 15px;
        }

        &:not(.active) p {
          font-weight: 300;
        }
      }
    }
  }

  vasta-segment.cadastrar-manejos {
    justify-content: center;
    background: #efefef;
    width: fit-content;
    margin: 8px auto 9px;
  }
}

vasta-segment.vasta-segment-manejos {
  &.animaisAtivo {
    .tab:first-child p {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background: #ccc;
        border-radius: 6px;
        margin: 4px 0 0;
        position: absolute;
        top: 3px;
        right: -7px;
        margin-top: 0;
      }
    }
  }

  &.lotesAtivo {
    .tab:not(:first-child):not(:last-child) p {
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background: #ccc;
        border-radius: 6px;
        margin: 4px 0 0;
        position: absolute;
        top: 3px;
        right: -7px;
        margin-top: 0;
      }
    }
  }

  &.areasAtivo {
    .tab:last-child p {
      position: relative;
      margin-right: 10px;

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        background: #ccc;
        border-radius: 6px;
        margin: 4px 0 0;
        position: absolute;
        top: 3px;
        right: -7px;
        margin-top: 0;
      }
    }
  }
}

app-fluxo-de-caixa {
  section.fertili-tabela .wrapper .linha:nth-child(2) .coluna {
    color: #3e8b83 !important;
  }

  section.fertili-tabela .wrapper .linha:nth-child(3) .coluna {
    color: #7abab4 !important;
  }

  section.fertili-tabela .wrapper .linha:nth-child(4) .coluna {
    color: #b34b4b !important;
  }

  section.fertili-tabela .wrapper .linha:nth-child(5) .coluna {
    color: #ce8383 !important;
  }
}

.grafico-wrapper {
  overflow-x: auto;
  overflow-y: hidden;

  .grafico-container {
    width: 100%;
    min-width: 600px;
  }

  &.larger {
    .grafico-container {
      width: 100%;
      min-width: 800px;
    }
  }
}

.add-nf {
  height: 44px;
  width: 100%;
  border-radius: 8px;
  color: #3e8c83;
  background: #e3eeed;
  position: relative;
  padding: 2px;
  margin-bottom: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  span {
    font-weight: bold;
    text-transform: uppercase;
  }

  ion-icon {
    font-size: 18px;
  }
}

.info-nf {
  padding: 0;
  list-style: none;

  li {
    width: 100%;
    padding: 4px 6px;
    background: #f3f3f3;
    color: #222;
    cursor: pointer;
  }
}

body #printWrapper {
  display: none;
}

@media print {
  body {
    -moz-osx-font-smoothing: initial;
    -webkit-font-smoothing: initial;
    margin-left: initial;
    margin-right: initial;
    margin-top: initial;
    margin-bottom: initial;
    padding-left: initial;
    padding-right: initial;
    padding-top: initial;
    padding-bottom: initial;
    position: initial;
    width: initial;
    max-width: initial;
    height: initial;
    max-height: initial;
    transform: initial;
    text-rendering: initial;
    overflow: initial;
    touch-action: initial;
    -webkit-user-drag: initial;
    -ms-content-zooming: initial;
    word-wrap: initial;
    overscroll-behavior-y: initial;
    -webkit-text-size-adjust: initial;
    text-size-adjust: initial;
  }

  body app-root {
    display: none;
  }

  body #printWrapper {
    display: block;
    width: 100%;
  }

  @page {
    margin:25px 30px;
  }

  html {
    font-family:"Oxygen",sans-serif
  }

  .titulo-impressao {
    flex:1;
    align-items:flex-start;
    justify-content:flex-start;

    h1 {
      font-size:28px;
      color:#006158;
      margin:0 22px;
      text-align:left;
      border-left:1px solid #ccc;
      padding-left:22px
    }
  }

  .container-kpi-cards {
    display:flex;
    flex-wrap:wrap;
    justify-content:flex-start;
    align-items:center;
    gap:20px;
    margin-bottom:25px
  }

  .kpi-card {
    position:relative;
    min-width:120px;
    height:60px;
    background-color:#f6f6f6;
    -webkit-print-color-adjust:exact;
    border-radius:6px;
    padding:0 10px 6px;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    align-items:flex-start;

    span {
      display:block;
      margin:0;
      width:80%;
      word-wrap:break-word;
      font-size:11px;
      line-height:12px;
      font-weight:400;
      color:#8f8f8f;
      text-transform:uppercase
    }

    b {
      font-size:20px;
      font-weight:700;
      color:#5a5a5a;
      white-space:nowrap;
      overflow:hidden;
      max-width:100%;
      text-overflow:ellipsis;
      height:25px;
      display:flex;
      align-items:flex-end;
      gap:2px;

      small {
        line-height:20px;
        opacity:.7;
        font-weight:initial
      }

      .indicador {
        align-self:center;
        display:inline-block;
        width:9px;
        height:9px;
        border-radius:5px
      }
    }
  }

  .verde, .success {
    background:#3e8c83
  }

  .amarelo, .warning {
    background:#ffcd02
  }

  .vermelho, .danger {
    background:#ff5d02
  }

  .container-comprovante {
    display:flex;
    flex-direction:column;
    padding:15px 40px;
    box-shadow:0 0 5px 0 rgba(0,0,0,.1);
    border-radius:6px;
    margin-bottom:20px
  }

  .comprovante-item {
    display:flex;
    justify-content:space-between;
    align-items:center;

    &:not(:last-child) {
      border-bottom:1px solid #e9e9e9
    }
  }

  .item-titulo {
    font-size:18px;
    color:#222;
    font-weight:700
  }

  .item-valor {
    font-size:16px;
    color:#5a5a5a;
    font-weight:700;

    small {
      font-size:12px;
      color:#8f8f8f;
      font-weight:400
    }
  }

  table {
    position:relative;
    width:100%;
    table-layout:fixed;
    overflow-wrap:break-word
  }

  .header {
    width:100%;
    padding:10px 0;
    display:flex;
    justify-content:space-between;
    align-items:center
  }

  .logo-impressao {
    padding: 10px;

    svg {
      width: 100px;
      height: 23px
    }

    img {
      width: 100px
    }
  }

  .header-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    text-align: right;
    font-weight: 300;
    margin-right: 10px
  }

  .headerTabela {
    padding: 10px;
    width: 100vw;
    display: flex;

    .header-col {
      padding: 5px 3px;
      color: #626262;
      font-weight: 700;
      white-space: nowrap;
      display: flex;
      flex-direction: column;

      small {
        text-transform: uppercase;
        font-weight: 300;
        color: #8f8f8f
      }
    }
  }

  .content {
    padding: 10px;
    width: 100vw;

    .content-row {
      width: 2000px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      page-break-inside: avoid
    }

    .content-col {
      padding: 8px 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;

      .chip {
        width: fit-content;
        max-width: fit-content;
        min-width: fit-content;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 2px 4px;
        font-size: 14px;
        margin-right: 4px
      }

      .align-right {
        direction: rtl
      }

      .placeholder {
        color: #ccc;
        font-size: 12px
      }

      svg {
        width: 25px
      }
    }

    span.chip-title {
      display: block;
      font-size: 8px;
      text-transform: uppercase
    }
  }

  .coluna-1 {
    min-width: 27px;
    max-width: 27px
  }

  .coluna-2 {
    min-width: 54px;
    max-width: 54px
  }

  .coluna-3 {
    min-width: 60px;
    max-width: 60px
  }

  .coluna-4 {
    min-width: 75px;
    max-width: 75px
  }

  .coluna-5 {
    min-width: 90px;
    max-width: 90px
  }

  .coluna-6 {
    min-width: 105px;
    max-width: 105px
  }

  .coluna-7 {
    min-width: 120px;
    max-width: 120px
  }

  .coluna-8 {
    min-width: 135px;
    max-width: 135px
  }

  .coluna-9 {
    min-width: 150px;
    max-width: 150px
  }

  .coluna-10 {
    min-width: 165px;
    max-width: 165px
  }

  .coluna-11 {
    min-width: 180px;
    max-width: 180px
  }

  .coluna-12 {
    min-width: 195px;
    max-width: 195px
  }

  .coluna-13 {
    min-width: 210px;
    max-width: 210px
  }

  .coluna-14 {
    min-width: 225px;
    max-width: 225px
  }

  .coluna-15 {
    min-width: 240px;
    max-width: 240px
  }

  .cards-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 350px));
    column-gap: 60px;
    row-gap: 20px;
    margin-bottom: 30px
  }

  .custom-card {
    background-color: #f6f6f6;
    -webkit-print-color-adjust: exact;
    border-radius: 7px;
    padding: 13px 20px;
    display: inline-block;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 0;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #565656;
      margin: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden
    }
  }

  .tabela-simples {
    .row {
      display: flex;
      white-space: nowrap;
      font-size: 14px;
      line-height: 28px;
      color: #565656
    }

    .row.head {
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;
      color: #939393
    }

    .col {
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;

      svg {
        width: 15px
      }

      &:first-child {
        width: 60px;
        min-width: 60px;
        max-width: 60px
      }

      .bold {
        font-weight: 700;
        flex: 1
      }

      .right {
        text-align: center;
        width: 30px;
        min-width: 30px;
        max-width: 30px
      }
    }
  }
}

.mono {
  font-family: "Oxygen Mono";
  letter-spacing: -0.3px;
}