// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3E8C83;
	--ion-color-primary-rgb: 62,140,131;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #377b73;
	--ion-color-primary-tint: #51988f;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #d87676;
  --ion-color-danger-shade: #be6868;
  --ion-color-danger-tint: #dc8484;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-male: #60b2e6;
  --ion-color-male-rgb: 96, 178, 230;
  --ion-color-male-contrast: #000000;
  --ion-color-male-contrast-rgb: 0, 0, 0;
  --ion-color-male-shade: #549dca;
  --ion-color-male-tint: #70bae9;

  --ion-color-female: #e679e6;
  --ion-color-female-rgb: 230, 121, 230;
  --ion-color-female-contrast: #000000;
  --ion-color-female-contrast-rgb: 0, 0, 0;
  --ion-color-female-shade: #ca6aca;
  --ion-color-female-tint: #e986e9;

  --ion-color-rebanho: #3E8C83;
  --ion-color-rebanho-rgb: 62,140,131;
  --ion-color-rebanho-contrast: #ffffff;
  --ion-color-rebanho-contrast-rgb: 255,255,255;
  --ion-color-rebanho-shade: #377b73;
  --ion-color-rebanho-tint: #51988f;

  --ion-color-location-sharp: #7359cd;
  --ion-color-location-sharp-rgb: 115,89,205;
  --ion-color-location-sharp-contrast: #ffffff;
  --ion-color-location-sharp-contrast-rgb: 255,255,255;
  --ion-color-location-sharp-shade: #654eb4;
  --ion-color-location-sharp-tint: #816ad2;

	--ion-color-manejo-pesagem: #7359cd;
	--ion-color-manejo-pesagem-rgb: 115,89,205;
	--ion-color-manejo-pesagem-contrast: #ffffff;
	--ion-color-manejo-pesagem-contrast-rgb: 255,255,255;
	--ion-color-manejo-pesagem-shade: #654eb4;
	--ion-color-manejo-pesagem-tint: #816ad2;

	--ion-color-manejo-ordenha: #c0ae4f;
	--ion-color-manejo-ordenha-rgb: 192,174,79;
	--ion-color-manejo-ordenha-contrast: #000000;
	--ion-color-manejo-ordenha-contrast-rgb: 0,0,0;
	--ion-color-manejo-ordenha-shade: #a99946;
	--ion-color-manejo-ordenha-tint: #c6b661;

  --ion-color-manejo-reprodutivo: #3e8c83;
	--ion-color-manejo-reprodutivo-rgb: 62,140,131;
	--ion-color-manejo-reprodutivo-contrast: #ffffff;
	--ion-color-manejo-reprodutivo-contrast-rgb: 255,255,255;
	--ion-color-manejo-reprodutivo-shade: #377b73;
	--ion-color-manejo-reprodutivo-tint: #51988f;

  --ion-color-manejo-sanitario: #ea7352;
	--ion-color-manejo-sanitario-rgb: 234,115,82;
	--ion-color-manejo-sanitario-contrast: #000000;
	--ion-color-manejo-sanitario-contrast-rgb: 0,0,0;
	--ion-color-manejo-sanitario-shade: #ce6548;
	--ion-color-manejo-sanitario-tint: #ec8163;

  --ion-color-manejo-evento: #5d5d5d;
	--ion-color-manejo-evento-rgb: 93,93,93;
	--ion-color-manejo-evento-contrast: #ffffff;
	--ion-color-manejo-evento-contrast-rgb: 255,255,255;
	--ion-color-manejo-evento-shade: #525252;
	--ion-color-manejo-evento-tint: #6d6d6d;

	--ion-color-manejo-chuva: #376b9c;
	--ion-color-manejo-chuva-rgb: 55,107,156;
	--ion-color-manejo-chuva-contrast: #ffffff;
	--ion-color-manejo-chuva-contrast-rgb: 255,255,255;
	--ion-color-manejo-chuva-shade: #305e89;
	--ion-color-manejo-chuva-tint: #4b7aa6;

  .ion-color-male {
    --ion-color-base: var(--ion-color-male);
    --ion-color-base-rgb: var(--ion-color-male-rgb);
    --ion-color-contrast: var(--ion-color-male-contrast);
    --ion-color-contrast-rgb: var(--ion-color-male-contrast-rgb);
    --ion-color-shade: var(--ion-color-male-shade);
    --ion-color-tint: var(--ion-color-male-tint);
  }
  
  .ion-color-female {
    --ion-color-base: var(--ion-color-female);
    --ion-color-base-rgb: var(--ion-color-female-rgb);
    --ion-color-contrast: var(--ion-color-female-contrast);
    --ion-color-contrast-rgb: var(--ion-color-female-contrast-rgb);
    --ion-color-shade: var(--ion-color-female-shade);
    --ion-color-tint: var(--ion-color-female-tint);
  }
  
  .ion-color-rebanho {
    --ion-color-base: var(--ion-color-rebanho);
    --ion-color-base-rgb: var(--ion-color-rebanho-rgb);
    --ion-color-contrast: var(--ion-color-rebanho-contrast);
    --ion-color-contrast-rgb: var(--ion-color-rebanho-contrast-rgb);
    --ion-color-shade: var(--ion-color-rebanho-shade);
    --ion-color-tint: var(--ion-color-rebanho-tint);
  }
  
  .ion-color-location-sharp {
    --ion-color-base: var(--ion-color-location-sharp);
    --ion-color-base-rgb: var(--ion-color-location-sharp-rgb);
    --ion-color-contrast: var(--ion-color-location-sharp-contrast);
    --ion-color-contrast-rgb: var(--ion-color-location-sharp-contrast-rgb);
    --ion-color-shade: var(--ion-color-location-sharp-shade);
    --ion-color-tint: var(--ion-color-location-sharp-tint);
  }

  .ion-color-manejo-pesagem {
    --ion-color-base: var(--ion-color-manejo-pesagem);
    --ion-color-base-rgb: var(--ion-color-manejo-pesagem-rgb);
    --ion-color-contrast: var(--ion-color-manejo-pesagem-contrast);
    --ion-color-contrast-rgb: var(--ion-color-manejo-pesagem-contrast-rgb);
    --ion-color-shade: var(--ion-color-manejo-pesagem-shade);
    --ion-color-tint: var(--ion-color-manejo-pesagem-tint);
  }

  .ion-color-manejo-ordenha {
    --ion-color-base: var(--ion-color-manejo-ordenha);
    --ion-color-base-rgb: var(--ion-color-manejo-ordenha-rgb);
    --ion-color-contrast: var(--ion-color-manejo-ordenha-contrast);
    --ion-color-contrast-rgb: var(--ion-color-manejo-ordenha-contrast-rgb);
    --ion-color-shade: var(--ion-color-manejo-ordenha-shade);
    --ion-color-tint: var(--ion-color-manejo-ordenha-tint);
  }

  .ion-color-manejo-reprodutivo {
    --ion-color-base: var(--ion-color-manejo-reprodutivo);
    --ion-color-base-rgb: var(--ion-color-manejo-reprodutivo-rgb);
    --ion-color-contrast: var(--ion-color-manejo-reprodutivo-contrast);
    --ion-color-contrast-rgb: var(--ion-color-manejo-reprodutivo-contrast-rgb);
    --ion-color-shade: var(--ion-color-manejo-reprodutivo-shade);
    --ion-color-tint: var(--ion-color-manejo-reprodutivo-tint);
  }

  .ion-color-manejo-sanitario {
    --ion-color-base: var(--ion-color-manejo-sanitario);
    --ion-color-base-rgb: var(--ion-color-manejo-sanitario-rgb);
    --ion-color-contrast: var(--ion-color-manejo-sanitario-contrast);
    --ion-color-contrast-rgb: var(--ion-color-manejo-sanitario-contrast-rgb);
    --ion-color-shade: var(--ion-color-manejo-sanitario-shade);
    --ion-color-tint: var(--ion-color-manejo-sanitario-tint);
  }

  .ion-color-manejo-evento {
    --ion-color-base: var(--ion-color-manejo-evento);
    --ion-color-base-rgb: var(--ion-color-manejo-evento-rgb);
    --ion-color-contrast: var(--ion-color-manejo-evento-contrast);
    --ion-color-contrast-rgb: var(--ion-color-manejo-evento-contrast-rgb);
    --ion-color-shade: var(--ion-color-manejo-evento-shade);
    --ion-color-tint: var(--ion-color-manejo-evento-tint);
  }

  .ion-color-manejo-chuva {
    --ion-color-base: var(--ion-color-manejo-chuva);
    --ion-color-base-rgb: var(--ion-color-manejo-chuva-rgb);
    --ion-color-contrast: var(--ion-color-manejo-chuva-contrast);
    --ion-color-contrast-rgb: var(--ion-color-manejo-chuva-contrast-rgb);
    --ion-color-shade: var(--ion-color-manejo-chuva-shade);
    --ion-color-tint: var(--ion-color-manejo-chuva-tint);
  }
}


